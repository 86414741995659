<template>
  <div class="mobile-footer">
    <div class="contact-section">
      <h4>Liên hệ</h4>
      <div class="contact-options">
        <a :href="`${$config.TELEGRAM}`" target="_blank" class="btn">
          <BaseImg src="/assets/images/components/common/telegram-logo.svg" class="icon-community" />
          Cộng đồng {{ $config.BRANCH_NAME }}
        </a>
        <a :href="`${$config.TELEGRAM_247}`" target="_blank" class="btn">
          <BaseImg src="/assets/images/components/common/telegram-logo.svg" class="icon-support" />
          @CSKH{{ $config.BRANCH_NAME }}
        </a>
      </div>
    </div>

    <div class="info-section">
      <h4>Thông tin chung</h4>
      <div class="info-options">
        <nuxt-link :key="'new'" :to="'/tin-tuc'" class="info-btn">
          <BaseImg src="/assets/images/components/mobile/modals/home/footer/icon-news.svg" class="icon-news" />
          Tin tức
        </nuxt-link>
        <nuxt-link :key="'about-us'" :to="'/gioi-thieu'" class="info-btn">
          <BaseImg src="/assets/images/components/mobile/modals/home/footer/icon-about.svg" class="icon-about" />
          Giới thiệu
        </nuxt-link>
        <nuxt-link :key="'new'" :to="'/gioi-thieu/dieu-khoan'" class="info-btn">
          <BaseImg src="/assets/images/components/mobile/modals/home/footer/icon-term.svg" class="icon-terms" />
          Điều khoản
        </nuxt-link>
        <nuxt-link :key="'new'" :to="'/gioi-thieu?page=security'" class="info-btn">
          <BaseImg src="/assets/images/components/mobile/modals/home/footer/icon-security.svg" class="icon-security" />
          Bảo mật
        </nuxt-link>
      </div>
    </div>

    <div class="brand-description">
      <p class="text-top">
        {{ dataSeo?.content_title }}
      </p>
      <p class="text-down">
        {{ dataSeo?.content_description }}
        <nuxt-link class="view-more" :to="PAGE_URLS.INTRODUCTION">{{ FOOTER_CONTENT.VIEW_MORE }}</nuxt-link>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { getFooterContent } from '~/constants/footer'
import { useAppStore } from '~/store/app'
import { PAGE_URLS } from '~~/config/page-url'
const { $config, $pinia } = useNuxtApp()
const { FOOTER_HOMEPAGE_MB_CONTENT, FOOTER_CONTENT } = getFooterContent()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()
const dataSeo = computed(() => {
  return seoData.value.find((item) => item.alias === '/')
})
</script>

<style lang="scss" src="assets/scss/components/mobile/pages/home/footer/index.scss"></style>
