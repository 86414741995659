import { useLobbyGameApi } from '~/api/lobby-game'
import { useLoading } from '@/composables/useLoading'

export function useHotGame() {
  const { $axios } = useNuxtApp()
  const { HOT_GAMES } = useLobbyGameApi()
  const { isLoading, loading, load } = useLoading()
  const gameHotData = ref([])

  const fetchHotGame = async () => {
    loading()
    try {
      const { data: response } = await $axios.get(HOT_GAMES, {
        params: {
          limit: 10,
          page: 1,
          path_alias: 'all',
          sort_by: 'HOT_GAME'
        }
      })
      load()
      if (response && response.status === 'OK') {
        if (response.data.items.length) {
          gameHotData.value = response.data.items
        }
      }
    } catch (error) {
      load()
    }
  }

  return {
    isLoading,
    gameHotData,
    fetchHotGame
  }
}
