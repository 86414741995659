<template>
  <section class="top-games">
    <div class="background-top-games">
      <div class="top-games__title">
        <h2>Top games</h2>
      </div>
      <div class="container">
        <div class="top-games__body">
          <HomeCategoryGames :games="gameHotData" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import HomeCategoryGames from '~/components/mobile/pages/home/top-games/game-item.vue'
import { useHotGame } from '@/composables/useHotGame'
import { useJackpot } from '~/composables/game/useJackpot'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))

const { fetchHotGame, gameHotData } = useHotGame()
const { fetchJackpot } = useJackpot()

useAsyncData(async () => {
  await fetchHotGame()
  await fetchJackpot()
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/top-games/index.scss"></style>
