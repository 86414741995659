<template>
  <section class="discount-category container-custom">
    <div class="discount-category__title">
      <h2>Khuyến mãi</h2>
    </div>
    <div class="discount-category__content">
      <div class="discount-bottom">
        <nuxt-link
          v-for="(itemDiscountBottom, index) in listDiscountBottom"
          :key="index"
          class="discount-bottom__item"
          :to="PAGE_URLS.PROMOTION"
        >
          <div class="banner" :class="itemDiscountBottom.class">
            <div class="title">
              <h2 class="title__top">{{ itemDiscountBottom.titleTop }}</h2>
              <h2 class="title__bottom">{{ itemDiscountBottom.titleBottom }}</h2>
            </div>
            <BaseImg :src="itemDiscountBottom.img" :alt="itemDiscountBottom.alt" lazy class="img-thumb" />
          </div>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { PAGE_URLS } from '~~/config/page-url'

const listDiscountBottom = reactive([
  {
    alt: 'Ưu dãi thành viên mới',
    route: PAGE_URLS.PROMOTION,
    titleTop: 'ƯU ĐÃI',
    titleBottom: 'THÀNH VIÊN MỚI',
    class: 'newbie',
    img: '/assets/images/components/desktop/pages/home/discount-category/thumb-thanh-vien-moi.webp'
  },
  {
    alt: 'Lên vip liền nhận tiền to',
    route: PAGE_URLS.PROMOTION,
    titleTop: 'LÊN VIP LIỀN',
    titleBottom: 'NHẬN TIỀN TO',
    class: 'vip',
    img: '/assets/images/components/desktop/pages/home/discount-category/thumb-nap-vip.webp'
  },
  {
    alt: 'hoàn trả cao nhất',
    route: PAGE_URLS.PROMOTION,
    titleTop: 'HOÀN TRẢ',
    titleBottom: ' CAO NHẤT',
    class: 'refund',
    img: '/assets/images/components/desktop/pages/home/discount-category/thumb-hoan-tra.webp'
  }
])
</script>
<style lang="scss" scoped src="~/assets/scss/components/desktop/pages/home/discount-category/index.scss"></style>
