<template>
  <NuxtLink :to="category.url" class="hover-scale">
    <div class="item" :style="{ backgroundImage: `url(${category.objectImgBg})` }">
      <div class="item__title">
        {{ category.text }}
      </div>
      <BaseImg :src="category.objectImg" lazy />
      <div v-if="!!sumJackpot[category.jackpot]" class="item__sub-title">
        <h2>
          <AnimateNumber :number="sumJackpot[category.jackpot]" :show-coin="true" />
          &nbsp;
          <p>K</p>
        </h2>
      </div>
    </div>
  </NuxtLink>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { useJackpot } from '~/composables/game/useJackpot'
import AnimateNumber from '~/components/common/animate-number.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser, sumJackpot, jackpot } = storeToRefs(store)
const { fetchSumJackpot } = useJackpot()

const props = defineProps({
  category: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: 0
  }
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/desktop/pages/home/featured-category/item.scss"></style>
