<template>
  <section class="featured-category">
    <div class="background-featured-category">
      <div class="container">
        <div class="featured-category__body">
          <Item
            v-for="(category, index) in homepageCategories"
            v-show="index < 9"
            :key="index"
            :category="category"
            :index="index"
            @click="router.push(category.url)"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import Item from './item.vue'
import { homepageCategories } from '@/resources/featured'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const router = useRouter()
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/featured-category/index.scss"></style>
