<template>
  <div class="provider-item-container">
    <client-only>
      <div v-if="providers && providers.length" class="mySwiper">
        <Swiper ref="provider" v-bind="swiperOption" class="mySwiper">
          <SwiperSlide v-for="(item, key) in providers" :key="key" class="swiper-slide">
            <div class="provider-items">
              <div class="provider-item">
                <div class="provider-logo">
                  <NuxtLink :to="item.url">
                    <BaseImg :src="item.provider.logo" :alt="item.provider.name" />
                  </NuxtLink>
                </div>
                <div class="games">
                  <div v-for="(game, idx) in item.games" :key="idx" class="game">
                    <NuxtLink :to="game.url">
                      <BaseImg :src="game.image" :alt="game.alt" />
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow } from 'swiper'
import BaseImg from '~~/components/common/base-img.vue'

const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  spaceBetween: 8,
  pagination: {
    clickable: true
  },
  modules: [Autoplay, Pagination, EffectCoverflow]
})

const props = defineProps({
  providers: {
    type: Array<any>,
    required: true
  }
})
</script>

<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/provider-category/provider-item.scss"></style>
