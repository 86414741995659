<template>
  <div class="footer-homepage">
    <div class="container">
      <h4 class="footer-homepage__title">{{ dataSeo?.content_title }}</h4>
      <div class="footer-homepage__description">{{ dataSeo?.content_description }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()
const dataSeo = computed(() => {
  return seoData.value.find((item) => item.alias === '/')
})
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/footer-homepage/index.scss"></style>
