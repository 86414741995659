import { LOBBY_CASINO_URLS, LOBBY_GAME_URLS, PAGE_URLS } from '~~/config/page-url'
import { IFeaturedCategory, IProviderCategory } from '~~/types/home'
import { PAGE } from '~/constants/router'

export const homepageCategories: IFeaturedCategory[] = [
  {
    url: '/the-thao',
    text: 'Thể thao',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-thethao-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-thethao.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156
  },
  {
    url: LOBBY_GAME_URLS.TABLE_GAME,
    text: 'Table Games',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-table-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-tablegame.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156,
    jackpot: 'jackpotIngame'
  },
  {
    url: LOBBY_GAME_URLS.NO_HU,
    text: 'Nổ Hũ',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-nohu-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-nohu.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156,
    jackpot: 'jackpotNohu'
  },
  {
    url: LOBBY_GAME_URLS.GAME_BAI,
    text: 'Game Bài',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-gamebai-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-gamebai.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156
  },
  {
    url: LOBBY_GAME_URLS.LO_DE,
    text: 'Lô Đề',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-lode-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-lode.webp',
    w: 220,
    h: 156
  },
  {
    url: LOBBY_GAME_URLS.BAN_CA,
    text: 'Bắn Cá',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-banca-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-banca.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156,
    jackpot: 'jackpotFishing'
  },
  {
    url: LOBBY_GAME_URLS.SLOTS_GAME,
    text: 'Slots',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-slots-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-slots.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156
  },
  {
    url: LOBBY_GAME_URLS.QUAY_SO,
    text: 'Quay Số',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-quayso-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-quayso.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156
  },
  {
    url: LOBBY_CASINO_URLS.ALL,
    text: 'Live Casino',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-casino-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-casino.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156
  },

  {
    url: LOBBY_GAME_URLS.QUICK_GAMES,
    text: 'Game Nhanh',
    objectImg: '/assets/images/components/desktop/pages/home/featured-category/img-gamenhanh-obj.webp',
    objectImgBg: '/assets/images/components/desktop/pages/home/featured-category/bg/bg-gamenhanh.webp',
    sizes: 'xl:220px',
    w: 220,
    h: 156
  },
  {
    url: '',
    text: 'Khuyến Mãi',
    objectImgBg: '',
    sizes: 'xl:190px',
    w: 109,
    h: 109
  }
]

export const providerCategories: IProviderCategory[] = [
  {
    provider: {
      logo: 'assets/images/components/desktop/pages/home/provider-category/evolution-gaming-logo.webp',
      name: 'Evolution Gaming',
      url: '/livecasino/all?partner=evo'
    },
    games: [
      {
        title: 'SPEED BACCARAT',
        image: 'assets/images/components/desktop/pages/home/provider-category/speed-baccarat-img.webp',
        alt: 'Speed Baccarat Image 1',
        url: 'livecasino/baccarat'
      },
      {
        title: 'SPEED BACCARAT G',
        image: 'assets/images/components/desktop/pages/home/provider-category/speed-baccarat-g.webp',
        alt: 'Speed Baccarat G Image',
        url: 'livecasino/baccarat'
      },
      {
        title: 'BACCARAT CONTROL SQUEEZE',
        image: 'assets/images/components/desktop/pages/home/provider-category/baccarat-control-squeeze-img.webp',
        alt: 'Baccarat Control Squeeze Image',
        url: 'livecasino/baccarat'
      }
    ]
  },
  {
    provider: {
      logo: 'assets/images/components/desktop/pages/home/provider-category/go88-logo.webp',
      name: 'Go88',
      url: '/game/table-game?provider=go'
    },
    games: [
      {
        title: 'Tai xiu',
        image: 'assets/images/components/desktop/pages/home/provider-category/taixiu.webp',
        alt: 'Tai xiu',
        url: '/livecasino/tai-xiu'
      },
      {
        title: 'Xoc dia',
        image: 'assets/images/components/desktop/pages/home/provider-category/xocdia.webp',
        alt: 'Xoc dia',
        url: '/livecasino/xoc-dia'
      },
      {
        title: 'Mini poker',
        image: 'assets/images/components/desktop/pages/home/provider-category/mini-poker.webp',
        alt: 'Mini poker',
        url: '/livecasino/poker'
      }
    ]
  },
  {
    provider: {
      logo: 'assets/images/components/desktop/pages/home/provider-category/techplay-logo.webp',
      name: 'Techplay',
      url: '/game/slots?provider=vingame'
    },
    games: [
      {
        title: 'Tam quoc tranh hung',
        image: 'assets/images/components/desktop/pages/home/provider-category/tamquoc.webp',
        alt: 'Tam quoc tranh hung',
        url: '/game'
      },
      {
        title: 'Na tra dai chien',
        image: 'assets/images/components/desktop/pages/home/provider-category/natra.webp',
        alt: 'Na tra dai chien',
        url: '/game'
      },
      {
        title: 'Tay du dai nao',
        image: 'assets/images/components/desktop/pages/home/provider-category/taydu.webp',
        alt: 'Tay du dai nao',
        url: '/game'
      }
    ]
  }
]
