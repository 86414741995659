<template>
  <section class="top-games">
    <div class="background-top-games" />
    <div class="container-custom">
      <div class="top-games__title">
        <h2>
          <CommonBaseImg :src="'assets/images/components/desktop/icon-crown.svg'" alt="icon crown" />
          Top games
        </h2>
      </div>
      <div class="top-games__body">
        <HomeCategoryGames :games="gameHotData" />
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import HomeCategoryGames from '~/components/desktop/pages/home/top-games/game-item.vue'
import { useHotGame } from '@/composables/useHotGame'
import { useJackpot } from '~/composables/game/useJackpot'

const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))

const { fetchHotGame, gameHotData } = useHotGame()
const { fetchJackpot } = useJackpot()

useAsyncData(async () => {
  await fetchHotGame()
  await fetchJackpot()
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/desktop/pages/home/top-games/index.scss"></style>
