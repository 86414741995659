<template>
  <GameItem v-for="(game, index) in games.slice(0, 9)" :key="index" :item="game" class="game-hot__list" />
</template>

<script setup lang="ts">
import GameItem from '~/components/common/game-item.vue'
import { IGameItem } from '~/types/common'
const router = useRouter()

const props = withDefaults(defineProps<{ games: IGameItem[] }>(), {
  games: () => []
})
</script>

<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/top-games/game-item.scss"></style>
